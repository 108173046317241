<template>
    <div>
      <h1>{{ message }}</h1>
      <button @click="updateMessage">Change Message</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        message: "Hello, Vue 2!"
      };
    },
    methods: {
      updateMessage() {
        this.message = "Message Updated";
      }
    }
  };
  </script>
  
  <style scoped>
  h1 {
    color: blue;
  }
  </style>  